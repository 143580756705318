<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title v-if="getOrderInfo">
          Заказ № {{ orderId }} от {{ getOrderInfo.created | dateformat }} {{ subTitle }}
        </v-toolbar-title>
        <v-toolbar-title v-else>
          Новый заказ {{ subTitle }}
        </v-toolbar-title>
        <v-spacer/>
        <div v-if="!farmerOrHunter">
          <v-select
            v-if="!isMarketplaceManager && !isPurchasingManager"
            v-model="stock"
            :items="stockList"
            hide-details
            label="Склад"
            class="mr-2 stock-select"
          />
          <!-- Поле отображается только для Менеджера маркетплейсов, отображает выбранный Маркетплейс склад -->
          <v-text-field
            v-if="isMarketplaceManager || isPurchasingManager"
            :value="getStockList().filter(el => el.value === getStockTo)[0]['text']"
            label="Склад"
            readonly
          />
        </div>
        <v-btn
          color="primary"
          class="mr-2"
          @click="addItems"
        >
          Добавить позиции
        </v-btn>
        <v-btn
          :color="isMarketplaceManager && getOrderType === 'FBS' ? 'yellow' : 'success'"
          :disabled="getItems.length === 0 || !bigform || isSubmitting"
          @click="submitOrder()"
        >
          <template v-if="getOrderInfo">
            Сохранить
          </template>
          <template v-else-if="isMarketplaceManager && getOrderType === 'FBS'">
            Зарезервировать
          </template>
          <template v-else-if="isMarketplaceManager && getOrderType === 'FBO'">
            <v-icon left>
              mdi-arrow-up-bold-box-outline
            </v-icon>
            Отправить заказ
          </template>
          <template v-else>
            <v-icon left>
              mdi-arrow-up-bold-box-outline
            </v-icon>
            Создать заказ
          </template>
        </v-btn>
      </v-toolbar>

      <v-container>
        <div v-if="isWholesaleHunter">
          <v-switch
            v-model="wholesaleSettings"
            :label="'Создать нового Партнёра ОПТ'"
            dense
            class="d-flex align-top"
          ></v-switch>
        </div>
        <div v-if="farmerOrHunter">
          <v-text-field
            v-if="wholesaleSettings"
            v-model="wholesale"
            outlined
            :rules="emailRules"
            label="Email Партнёр ОПТ"
            prepend-inner-icon="mdi-email"
          ></v-text-field>
          <div v-if="!wholesaleSettings">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4">
                <v-select
                  v-model="wholesaleCompany"
                  :items="wholesaleCompaniesItems"
                  item-text="name"
                  item-value="id"
                  hide-details
                  label="Компания"
                  @change="getWholesaleStocks"
                  class="stock-select"
                >
                </v-select>
              </v-col>
              <v-col
                v-if="wholesaleCompany"
                cols="12"
                sm="6"
                md="4">
                <v-select
                  v-model="stock"
                  :items="wholesaleStocksItems"
                  item-text="name"
                  item-value="id"
                  hide-details
                  label="Склад"
                  class="stock-select"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>
      <v-row justify="end">
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <!-- Введено свойство v-if для DatePickerRange, для того чтобы не отображать DatePickerRange, если авторизован
        Менеджер маркетплейсов -->
          <DatePickerRange
            v-if="hideDatePeriod"
            v-model="pSoldDateRange"
            label="Продано за период"
          />
        </v-col>
      </v-row>
      <v-form v-model="bigform">
        <v-data-table
          :headers="tableHeaders"
          :items="getItems"
          item-key="id"
          flat
          disable-pagination
          hide-default-footer
          :loading="getLoadingState"
        >
          <template #[`item.amount`]="{ item }">
            {{ calculateItemAmount(item) | currency(`USD`) }}
          </template>
          <template #[`item.price`]="{ item }">
            {{ item[getPriceField()] ? currency(item[getPriceField()].toString(), 'USD') : '0' }}
          </template>
          <template #[`item.order`]="{ item }">
            <v-text-field
              v-model="item.order"
              :rules="[rules.required]"
              :error-messages="validateQuantity(item)"
              style="width: 100px"
              dense
              required
              @input="pushItems({ payload: getItems })"
            />
          </template>
          <template #[`item.actions`]="{ index }">
            <v-icon @click="deleteItem(index)">
              mdi-delete
            </v-icon>
          </template>
          <template #[`body.append`]="{ headers }">
            <tr>
              <th>Итого</th>
              <th :colspan="headers.length - 5"/>
              <th>{{ getQuantity }}</th>
              <th/>
              <th>{{ getAmount() | currency(`USD`) }}</th>
              <th/>
            </tr>
          </template>
        </v-data-table>
      </v-form>
    </v-card>
  </v-main>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import clients from "@/api/clients";
import DatePickerRange from "@/components/DatePickerRange.vue";

export default {
  name: "OrderCreate",
  components: {DatePickerRange},
  data: () => ({
    isSubmitting: false,
    bigform: false,

    stock: "",

    rules: {
      required: (v) => !!v || "Обязательное поле",
    },
    emailRules: [
      (v) => !!v || "Обязательное поле",
      (v) => /.+@.+\..+/.test(v) || "Введите корректный email",
    ],
    editedItem: {
      product: "",
      order: 1,
    },
    defaultItem: {
      product: "",
      order: 1,
    },
    pSoldDateRange: [
      new Date(new Date().setDate(new Date().getDate() - 31))
        .toISOString()
        .substr(0, 10),
      new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .substr(0, 10),
    ],
    pStock: "",
    wholesale: "",
    wholesaleSettings: false,
    wholesaleCompany: "",
    wholesaleCompaniesItems: [],
    wholesaleStocksItems: [],
    orderOld: [],  // Список создан для того, чтобы правильно работало редактирование заявки, для уже добавленных
    // продуктов учитывается как количество, оставшееся на складе, так и уже зарезервированное, а если добавляется
    // новый продукт, то для него количество ограничивается количеством товара на складе.
    // Список хранит количество
    // продуктов уже добавленных в заявку
  }),
  computed: {
    ...mapGetters("auth", ["isMarketplaceManager", "isWholesaleHunter", "getOrderMinSum", "isWholesaleFarmer", "isPurchasingManager", "getChinaOrderMinSum", "getUserCompany"]),
    ...mapGetters("clients", ["getStockList"]),
    ...mapGetters("stocks", [
      "getItems",
      "getLoadingState",
      "getOrderId",
      "getOrderInfo",
      "getOrderFromChina",
      "getQuantity",
      "getStockTo",
      "getOrderType",
      "getSoldDateRange"
    ]),
    ...mapState("clients", ["stockList"]),
    tableHeaders() {
      let headers = [
        {text: "Артикул", value: "vendor_code", isMarketplaceManager: false},
        {text: "Наименование", value: "title"},
        {text: "Штрих-код", value: "bar_code", isMarketplaceManager: true},
        {text: "Артикул", value: "vendor_code", isMarketplaceManager: true},
        {text: "Текущий остаток в магазине", value: "quantity_on_stocks", isMarketplaceManager: false, isPurchasingManager: false},
        {
          text: this.isPurchasingManager ? 'Остаток на складе УК' : "Остаток на складе поставщика",
          value: "quantity_on_base_stocks",
          orderFromChina: false,
        },
        {text: "Количество", value: "order"},
        {
          text: "Продано",
          value: "quantity_sold",
          isMarketplaceManager: false,
          isWholeSaleGroup: false,
        },
        {text: "Цена товара", value: 'price'},
        {text: "Сумма заказа", value: "amount"},
        {text: "", value: "actions"},
      ];
      headers = this.isMarketplaceManager
        ? headers.filter((el) => el.isMarketplaceManager !== false)
        : headers.filter((el) => el.isMarketplaceManager !== true);
      headers = this.isPurchasingManager
        ? headers.filter((el) => el.isPurchasingManager !== false)
        : headers.filter((el) => el.isPurchasingManager !== true);
      return this.getOrderFromChina
        ? headers.filter((el) => el.orderFromChina !== false)
        : headers;
    },
    orderId() {
      return this.$route.params.id || null;
    },
    subTitle() {
      if (this.isMarketplaceManager) {
        return this.getOrderType
      } else {
        if (this.getOrderFromChina) {
          return "(с производства)";
        } else {
          return "(со склада)";
        }
      }
    },
    wholesaleMail: function () {
      return this.wholesale.toString().toLowerCase().trim();
    },
    farmerOrHunter: function () {
      return this.isWholesaleFarmer || this.isWholesaleHunter;
    },
    hideDatePeriod() {
      return !(this.isMarketplaceManager || this.isWholeSaleGroup);
    },
  },
  watch: {
    stock() {
      if (!this.isMarketplaceManager) {
        if (!this.getLoadingState) {
          this.getStocksItems()
        }
      }
    },
    stockList(v) {
      if (v && v.length > 0) this.stock = v[0].value;
      return v;
    },
    bigform(val) {
      !val || this.pushItems({payload: this.getItems});
    },
    wholesaleSettings(v) {
      !v && (this.wholesale = "");
    },
    pSoldDateRange(date) {
      if (date.length === 2) {
        if (Date.parse(date[0]) > Date.parse(date[1])) date.reverse();
        this.getStocksItems();
      }
    },
  },
  async mounted() {
    console.log('this.orderId', this.orderId)
    await this.updateStockListFromOrderEdit();
    if (this.getStockTo){
      this.stock = this.getStockTo;
    }
    if (this.getSoldDateRange && this.getSoldDateRange.length === 2) {
      this.pSoldDateRange = this.getSoldDateRange;
    }
    this.getStocksItems()
    if (this.orderId) {
      this.getOrder({orderId: this.orderId});
    } else if (this.getOrderId) {
      this.clearOrder();
    }
    if (this.farmerOrHunter) {
      this.getWholesaleCompanies();
    }
  },


  methods: {
    ...mapActions(["alertSuccess", 'alertInfo']),
    ...mapActions("auth", ["getUserGroup",]),
    ...mapActions("clients", ["updateStockListFromOrderEdit",]),
    ...mapActions("catalog", ["updateProducts"]),
    ...mapActions("stocks", [
      "clearOrder",
      "createOrder",
      "getOrder",
      "pushItems",
      "removeItem",
      "setOrderId",
      "updateOrder",
      "updateStockItems",
    ]),
    currency(amount, currency) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount);
    },
    validateOrder() {
      let valid = true;
      let minSum;
      if (this.getOrderFromChina) {
        minSum = this.getChinaOrderMinSum
      } else {
        minSum = this.getOrderMinSum
      }
      if (this.getAmount < minSum) {
        this.$store.dispatch("alertError", "Минимальная сумма заказа - $" + minSum.toString());
        valid = false;
      }
      if (this.stockList.length < 1) {
        valid = false;
        this.$store.dispatch("alertError", "Пожалуйста, заполните информацию о компании и складе для совершения заказа")
      }
      return valid;
    },
    validateQuantity({id, order, quantity_on_base_stocks, min_order_factory_quantity}) {
      if (this.getOrderFromChina) {
        return (window.isFinite(order) &&
          Number.parseInt(order) >= min_order_factory_quantity &&
          Number.parseInt(order) === Number(order))
          ? ""
          : `Минимум ${min_order_factory_quantity} шт.`;
      } else {
        if (this.getOrderInfo) {
          let filtered_item = this.orderOld.filter(el => el.id === id)[0]
          return !order ||
          (window.isFinite(order) &&
            Number.parseInt(order) >= 1 &&
            Number.parseInt(order) <= quantity_on_base_stocks + (filtered_item ? filtered_item.order : 0) &&
            Number.parseInt(order) === Number(order))
            ? ""
            : `Минимум 1 шт., не больше ${quantity_on_base_stocks + (filtered_item ? filtered_item.order : 0)} шт.`;
        } else {
          return !order ||
          (window.isFinite(order) &&
            Number.parseInt(order) >= 1 &&
            Number.parseInt(order) <= quantity_on_base_stocks &&
            Number.parseInt(order) === Number(order))
            ? ""
            : `Минимум 1 шт., не больше ${quantity_on_base_stocks} шт.`;
        }
      }
    },
    submitOrder() {
      if (this.validateOrder()) {
        const visibleItems = this.getItems.map(item => item.product); // Получаем список ID видимых товаров
        const currentItems = this.$store.state.stocks.items;

        // Удаляем невидимые товары
        currentItems.forEach((item, index) => {
          if (!visibleItems.includes(item.product)) {
            this.$store.commit('stocks/removeItem', index);
          }
        });

        this.isSubmitting = true;
        if (this.wholesaleMail) {
          // управляющая компания
          this.wholesaleCompany = 7
        }
        if (this.isPurchasingManager)
          this.wholesaleCompany = this.getUserCompany
        if (this.isMarketplaceManager) {
          this.stock = this.getStockTo
        }
        const request = this.orderId
          ? this.updateOrder({orderId: this.orderId, stock: this.stock})
          : this.createOrder({stock: this.stock, wholesaleMail: this.wholesaleMail, company: this.wholesaleCompany});

        request.finally(() => {
          this.isSubmitting = false;
        });
      }
    },
    deleteItem(index) {
      this.removeItem(index);
      this.setOrderId(this.orderId);
      this.$store.dispatch("alertSuccess", "Товар удалён из заказа");
    },
    addItems() {
      if (this.orderId) {
        this.setOrderId(this.orderId);
      }
      this.$router.push({name: "StocksView"});
    },
    getWholesaleCompanies() {
      return clients.getWholesaleCompanyList().then(companies => {
        return this.wholesaleCompaniesItems = companies.data
      })
    },
    getWholesaleStocks() {
      return clients.getStockList(this.wholesaleCompany).then(stocks => {
        let stocksData = stocks.data
        this.stock = stocksData[0].id
        return this.wholesaleStocksItems = stocks.data
      })
    },
    getPriceField() {
      if (this.getOrderFromChina) {
        return 'price_from_china'
      } else {
        return 'price'
      }
    },
    calculateItemAmount(item) {
      return item.order * (item[this.getPriceField()] || 0);
    },
    getItemsAmount() {
      return this.getAmount(this.getOrderFromChina);
    },
    getAmount() {
      const items = this.getItems;
      if (items.length === 0) return 0;
      return items.reduce((acc, value) => acc + Number(value.order) * Number(value[this.getPriceField()]), 0);
    },
    getStocksItems() {
      this.updateStockItems({
        stock: this.stock,
        sold_date__gte: this.pSoldDateRange[0],
        sold_date__lte: this.pSoldDateRange[1],
      }).then(() => {
        this.orderOld = this.getOrderInfo.items.map(el => ({id: el.product.id, order: el.quantity}));
      });
    },
  },
};
</script>

<style scoped>
.stock-select {
  width: 15em;
}
</style>
